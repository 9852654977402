import cn from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import styles from "./styles.module.scss";

const Resources = () => {
  const { resources } = useStaticQuery(
    graphql`
        query {
            resources: allResourcesYaml {
                edges {
                    node {
                        title
                        description
                        link
                        image {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }

    `
  );

  return (
    <>
      <div className="row">
        <div className="col">
          <h2>
            Resources
          </h2>
        </div>
      </div>

      <div className={cn("pb-5", styles.container)}>
        {resources.edges.map(({ node: { title, description, link, image } }, index) => (
          <div className="mt-4" key={index}>
            <div className={styles.resource}>
              <a href={link} className={styles.link} target="_blank" rel="noopener noreferrer">
                <Img className={styles.image} fluid={image.childImageSharp.fluid}/>

                <span className={styles.resourceContent}>
                  <span className={cn(styles.resourceTitle, "type-24")}>
                    {title}
                  </span>
                  <span className={cn(styles.resourceDescription, "type-20")}>
                    {description}
                  </span>
                </span>
              </a>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Resources;

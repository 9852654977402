import cn from "classnames";
import React from "react";
import BarChartGroup from "../components/BarChartGroup";
import DiscoverLink from "../components/DiscoverLink";
import Footer from "../components/Footer";
import Header from "../components/Header";
import FeminineHighlight from "../components/Highlight/FeminineHighlights";
import MasculineHighlight from "../components/Highlight/MasculineHighlight";
import Layout from "../components/Layout";
import Resources from "../components/Resources";
import Seo from "../components/Seo";
import ShapeSeparator from "../components/ShapeSeparator";
import Sources from "../components/Sources";
import insightsData from "../data/insights.yaml";
import InsightsSVG from "../images/insights-illustration.inline.svg";
import styles from "./insights.module.scss";

const Insights = () => (
  <Layout>
    <Seo title="The Gender Bias Decoder Insights"/>

    <Header
      className="insights"
      title={insightsData.title}
    />

    <div className={cn(styles.content, "insights-page")}>
      <div className="container-fluid container-xl">
        <div className="row pb-5 justify-content-between">
          <div className="col-12 col-lg-5 pt-5">
            <p className={cn(styles.intro, "type-24")}>
              {insightsData.intro}
            </p>

            <DiscoverLink/>
          </div>
          <div className="col-12 col-lg-6 pt-5">

            <h2 className={cn(styles.totalAdsTitle, "type-20")}>
              {insightsData.totalAdsTitle}
            </h2>

            <span className={cn(styles.totalAdsNumber, "type-42")}>
              {insightsData.totalAdsNumber}
            </span>

            <ShapeSeparator width={50}/>

            <BarChartGroup
              title={insightsData.breakdown.title}
              male={insightsData.breakdown.male}
              female={insightsData.breakdown.female}
              unbiased={insightsData.breakdown.un}
            />
          </div>
        </div>
        <div className={cn("row", styles.highlight)}>
          <div className="col-12 col-lg-6">
            <h2 className="type-24">
              {insightsData.commonWords.masculineTitle}
            </h2>

            <ul className={styles.list}>
              {insightsData.commonWords.masculine.map((word, index) => (
                <li key={index}>
                  <MasculineHighlight className="type-24">
                    {word}
                  </MasculineHighlight>
                </li>
              ))}
            </ul>

          </div>
          <div className="col-12 col-lg-6">
            <h2 className="type-24">
              {insightsData.commonWords.feminineTitle}
            </h2>

            <ul className={styles.list}>
              {insightsData.commonWords.feminine.map((word, index) => (
                <li key={index}>
                  <FeminineHighlight className="type-24">
                    {word}
                  </FeminineHighlight>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className={cn("pt-5", styles.sectors)}>
          <div>
            <h2>
              {insightsData.biased.title}
            </h2>
            <p className="mb-0 type-20">
              {insightsData.biased.description}
            </p>
          </div>
          <div className="pt-4">
            <div className={styles.illustration}>
              <InsightsSVG/>
            </div>
          </div>
        </div>

        <div className={cn(styles.sectors, "pb-5")}>
          {insightsData.biased.sectors.map(({ label, male, female, un }, index) => (
            <div className="pt-5" key={index}>
              <BarChartGroup label={label} male={male} female={female} unbiased={un}/>
            </div>
          ))}
        </div>

        <div className="border-bottom"/>

        <div className="row py-5 justify-content-between">
          <div className="py-2 col-12 col-xl-5">
            <h2>
              {insightsData.senior.title}
            </h2>
            <p className="type-20">
              {insightsData.senior.description}
            </p>
          </div>

          {insightsData.senior.levels.map(({ label, male, female, un }, index) => (
            <div className={cn("pt-5 py-xl-2 col-12 col-sm-6 col-xl-3", { [styles.borderLeft]: index === 0 })}
                 key={index}>
              <BarChartGroup label={label} male={male} female={female} unbiased={un}/>
            </div>
          ))}

        </div>

        <div className="pb-5">
          <div className="border-bottom"/>
        </div>

        <Resources/>

      </div>
    </div>

    <div className="container-fluid container-xl">
      <Sources>
        <small>
          {insightsData.footnote}
        </small>
      </Sources>
    </div>

    <Footer/>
  </Layout>
);

export default Insights;

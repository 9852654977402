import cn from "classnames";
import React from "react";
import styles from "./styles.module.css";

const Title = ({ color: { fill, border }, children }) => (
  <span className={cn(styles.label, "type-20")}
        style={{ background: fill ? fill : null, border: border ? `1px solid ${border}` : null }}>
    {children}
  </span>
);

export default Title;

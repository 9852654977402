import cn from "classnames";
import React from "react";
import Bar from "./Bar";
import styles from "./styles.module.css";
import Title from "./Title";

const colors = {
  transparant: {
    fill: false,
    border: false
  },
  male: {
    fill: `#FFA887`,
    border: `#FF5513`
  },
  female: {
    fill: `#59C6C4`,
    border: `#008E8C`
  },
  unbiased: {
    fill: `#CA76A0`,
    border: `#8F0049`
  }
};

const BarChartGroup = ({ label, male, female, unbiased, title = "" }) => {
  const max = Math.max(male, female, unbiased);
  let color = colors.transparant;
  let text = title;

  if (text === "") {
    if (max === male) {
      color = colors.male;
      text = "Most male-biased";
    } else if (max === female) {
      color = colors.female;
      text = "Most female-biased";
    } else {
      color = colors.unbiased;
      text = "Most unbiased";
    }
  }

  return (
    <div className={styles.wrap}>
      <Title color={color}>
        {text}
      </Title>

      {label && (
        <span className={cn(styles.label, "type-42")}>
          {label}
        </span>
      )}

      <Bar percentage={male} label="Male-biased" color={colors.male}/>
      <Bar percentage={female} label="Female-biased" color={colors.female}/>
      <Bar percentage={unbiased} label="Unbiased" color={colors.unbiased}/>
    </div>
  );
};

export default BarChartGroup;

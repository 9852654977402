import React, { Component } from "react";
import ScrollPercentage from "react-scroll-percentage";

class OnScreen extends Component {
  constructor () {
    super();

    this.state = {
      seen: false
    };
  }

  render () {
    const { children } = this.props;
    const { seen } = this.state;

    return children(seen);
  }

  shouldComponentUpdate ({ onScreen }, { seen }, nextContext) {
    if (onScreen && !seen) {
      this.setState({
        seen: true
      });
    }

    return true;
  }

}

export const withOnScreen = (WrappedComponent, threshold = 0.2) => {
  return class extends Component {
    render () {
      return (
        <ScrollPercentage>
          {({ percentage, ref }) => {
            return (
              <div ref={ref}>
                <OnScreen onScreen={percentage >= threshold}>
                  {(onScreen) => (
                    <WrappedComponent onScreen={onScreen} {...this.props} />
                  )}
                </OnScreen>
              </div>
            );
          }}
        </ScrollPercentage>
      );
    }
  };
};

import cn from "classnames";
import React, { useEffect, useState } from "react";
import { withOnScreen } from "../../../hocs/onScreen";
import styles from "./styles.module.css";

const ANIMATION_TIME = 1000;

const Bar = ({ label, percentage, color: { fill, border }, onScreen }) => {
  const [showLabel, setShowLabel] = useState(false);

  useEffect(() => {
    if (!onScreen) {
      return;
    }

    const id = setTimeout(() => {
      setShowLabel(true);
    }, ANIMATION_TIME);

    return () => clearTimeout(id);
  }, [onScreen, setShowLabel]);

  const barStyle = {
    width: `${onScreen ? percentage : 0}%`,
    background: fill,
    borderColor: border,
    transition: `width ${ANIMATION_TIME}ms`
  };

  return (
    <div className={styles.wrap}>
      <span className={cn(styles.label, "type-16")}>
        {label}
      </span>
      <span className={cn(styles.number, "fade", { "show": showLabel }, "type-16")}>
        {percentage}%
      </span>

      <div className={styles.barWrap}>
        <div
          className={styles.bar}
          style={barStyle}
        />
        <div className={styles.emptyBar}/>
      </div>
    </div>
  );
};

export default withOnScreen(Bar);
